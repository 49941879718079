/* eslint-disable comma-dangle */
/* eslint-disable func-names */
/**
 * Easy selector helper function
 */
const select = (el, all = false) => {
  el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  }
  return document.querySelector(el);
};

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
  const selectEl = select(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
};

/**
 * Easy on scroll event listener
 */
const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener);
};

/**
 * Navbar links active state on scroll
 */
const navbarlinks = select('#navbar .scrollto', true);
const navbarlinksActive = () => {
  const position = window.scrollY + 200;
  navbarlinks.forEach((navbarlink) => {
    if (!navbarlink.hash) return;
    const section = select(navbarlink.hash);
    if (!section) return;
    if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
      navbarlink.classList.add('active');
    } else {
      navbarlink.classList.remove('active');
    }
  });
};
window.addEventListener('load', navbarlinksActive);
onscroll(document, navbarlinksActive);

/**
 * Scrolls to an element with header offset
 */
const scrollto = (el) => {
  const header = select('#header');
  let offset = header.offsetHeight;

  if (!header.classList.contains('header-scrolled')) {
    // offset -= 16
    offset -= 0;
  }

  const elementPos = select(el).offsetTop;
  window.scrollTo({
    top: elementPos - offset,
    behavior: 'smooth',
  });
};

/**
 * Toggle .header-scrolled class to #header when page is scrolled
 */
const selectHeader = select('#header');
if (selectHeader) {
  const headerScrolled = () => {
    if (window.scrollY > 100) {
      selectHeader.classList.add('header-scrolled');
    } else {
      selectHeader.classList.remove('header-scrolled');
    }
  };
  window.addEventListener('load', headerScrolled);
  onscroll(document, headerScrolled);
}

/**
 * Back to top button
 */
const backtotop = select('.back-to-top');
if (backtotop) {
  const toggleBacktotop = () => {
    if (window.scrollY > 100) {
      backtotop.classList.add('active');
    } else {
      backtotop.classList.remove('active');
    }
  };
  window.addEventListener('load', toggleBacktotop);
  onscroll(document, toggleBacktotop);
}

/**
 * Mobile nav toggle
 */
on('click', '.mobile-nav-toggle', function () {
  select('#navbar').classList.toggle('navbar-mobile');
  this.classList.toggle('bi-list');
  this.classList.toggle('bi-x');
});

/**
 * Mobile nav dropdowns activate
 */
on(
  'click',
  '.navbar .dropdown > a',
  function (e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault();
      this.nextElementSibling.classList.toggle('dropdown-active');
    }
  },
  true
);

/**
 * Scroll with ofset on links with a class name .scrollto
 */
on(
  'click',
  '.scrollto',
  function (e) {
    if (select(this.hash)) {
      e.preventDefault();

      const navbar = select('#navbar');
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile');
        const navbarToggle = select('.mobile-nav-toggle');
        navbarToggle.classList.toggle('bi-list');
        navbarToggle.classList.toggle('bi-x');
      }
      scrollto(this.hash);
    }
  },
  true
);

/**
 * Scroll with ofset on page load with hash links in the url
 */
window.addEventListener('load', () => {
  if (window.location.hash) {
    if (select(window.location.hash)) {
      scrollto(window.location.hash);
    }
  }
});
